import { commonReducer } from './common/slice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import formReducer from '@frontend/design_system/src/redux/forms/forms.reducer'
import { notificationReducer } from './notifications/slice'

const rootReducer = combineReducers({
  common: commonReducer,
  forms: formReducer,
  notificationReducer,
})

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = ReturnType<typeof setupStore>['dispatch']

export const store = setupStore()

import { Modal, Typography, LazyImage } from '@frontend/design_system'
import EmailSrc from 'img/Email.webp'
import styles from './index.module.scss'
import { SuccessEmailSentModalProps } from './SuccessEmailSentModal.types'
import { SUCCESS_EMAIL_SENT_MODAL } from 'texts/otherInfoForm'

export const SuccessEmailSentModal = ({
  onClose,
  title,
  description,
}: SuccessEmailSentModalProps) => (
  <Modal
    position="center-horizontal"
    onClose={onClose}
    isDelimiter
    offsetVariant="none"
    title={title}
    headerSize="large"
    dataTestId="success-api-account-modal"
  >
    <div className={styles.content}>
      <Typography
        color="grey500"
        name="body1WMedium"
        markColor="grey900Master"
        html={description}
      />
      <LazyImage
        src={EmailSrc}
        width={96}
        height={96}
        className={styles.image}
      />
      <Typography name="Button1" color="green700Master">
        {SUCCESS_EMAIL_SENT_MODAL.CHECK_EMAIL}
      </Typography>
    </div>
  </Modal>
)

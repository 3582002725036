import { EnvironmentVariablesService } from 'utils/env'
import { ContentType, HttpResponse } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const contentFormatter: Record<ContentType, (input: any) => any> = {
  [ContentType.JSON]: (input: unknown) =>
    input && typeof input === 'object' ? JSON.stringify(input) : input,
  [ContentType.FORM_DATA]: (input: Record<string, unknown>) =>
    Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      let value
      if (property instanceof Blob) {
        value = property
      } else {
        if (property && typeof property === 'object') {
          value = JSON.stringify(property)
        } else {
          value = `${property}`
        }
      }
      formData.append(key, value)
      return formData
    }, new FormData()),
}

export const request = async <T, E>(
  path: string,
  requestParams: RequestInit = { method: 'GET' },
  body?: unknown,
  type = ContentType.JSON
): Promise<HttpResponse<T, E>> => {
  const payloadFormatter = contentFormatter[type || ContentType.JSON]

  const response = await fetch(
    `${EnvironmentVariablesService.getEnv('REACT_APP_BACKEND_URL')}${path}`,
    {
      ...requestParams,
      credentials: 'include',
      headers: {
        ...(type && type !== ContentType.FORM_DATA
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      body: payloadFormatter(body),
    }
  )
  const res = response as HttpResponse<T, E>

  const data =
    response.headers.get('Content-Length') !== '0' ? await res.json() : {}
  try {
    if (res.ok) {
      res.data = data
    } else {
      res.error = data
    }
  } catch (e) {
    res.error = e as E
  }

  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res.error
  }

  return res
}

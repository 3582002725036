import {
  Form,
  FormItem,
  OriginButton,
  Input,
  Multiselect,
} from '@frontend/design_system'
import { STEPS } from 'modules/BecomePartnerForm/BecomePartnerForm.constants'
import { useEffect, useMemo, useState } from 'react'
import { countryOfResidence } from '@frontend/design_system/src/constants'
import { useDispatch, useSelector } from 'react-redux'
import { locationAndFleetFormSelector } from 'redux/common/selectors'
import {
  setCurrentStep,
  setLocationAndFleetFormValues,
} from 'redux/common/slice'
import { FormItemChildren } from 'types/formItem'
import { LOCATION_FORM_FIELDS } from 'texts/locationForm'
import { BACK, NEXT } from 'texts/form'
import { onlyIntegersRegex } from 'constants/regexSamples'
import styles from './index.module.scss'
import { api } from 'api'
import { LocationFormValues } from './LocationForm.types'
import { setNotificationMessage } from 'redux/notifications/slice'
import { ErrorResponse } from 'api/types'

export const LocationForm = () => {
  const dispatch = useDispatch()
  const locationFormValues = useSelector(locationAndFleetFormSelector)
  const [locationTypes, setLocationTypes] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    const fetchLocationTypes = async () => {
      try {
        const response = await api.getLocationTypes()
        setLocationTypes(
          response.data.map(({ name, id }) => ({ label: name, value: id }))
        )
      } catch (e) {
        dispatch(
          setNotificationMessage({
            notificationMessage: (e as ErrorResponse).responseStatus?.message,
            type: 'error',
          })
        )
      }
    }
    fetchLocationTypes()
  }, [])

  const onSubmit = async (values: LocationFormValues, validate: boolean) => {
    if (validate) {
      dispatch(setLocationAndFleetFormValues(values))
      dispatch(setCurrentStep(STEPS.otherInfo))
    }
  }

  const handleBack = () => {
    dispatch(setCurrentStep(STEPS.contactInfo))
  }

  const initValues = useMemo(
    () => ({
      [LOCATION_FORM_FIELDS.COUNTRIES.ID]: locationFormValues?.countries || [],
      [LOCATION_FORM_FIELDS.TYPE_OF_LOCATION.ID]:
        locationFormValues?.typeOfLocation || [],
      [LOCATION_FORM_FIELDS.VEHICLE_COUNT.ID]:
        locationFormValues?.vehicleCount || '',
      [LOCATION_FORM_FIELDS.LOCATION_COUNT.ID]:
        locationFormValues?.locationCount || '',
    }),
    []
  )

  const handleOnChange = (value: string, onChange: (value: string) => void) => {
    if (!value || onlyIntegersRegex.test(value)) {
      if (value.length > 1 && value[0] === '0') {
        onChange(value.slice(1))
      } else {
        onChange(value)
      }
    }
  }

  const formRules = useMemo(
    () => ({
      countries: [
        {
          type: 'required',
          message: LOCATION_FORM_FIELDS.COUNTRIES.REQUIRED_MESSAGE,
        },
      ],
      locationCount: [
        {
          type: 'required',
          message: LOCATION_FORM_FIELDS.LOCATION_COUNT.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: (value: string | number) => {
            if (Number(value) < 1) {
              return {
                message: LOCATION_FORM_FIELDS.LOCATION_COUNT.VALIDATION_MESSAGE,
              }
            }
          },
        },
      ],
      vehicleCount: [
        {
          type: 'required',
          message: LOCATION_FORM_FIELDS.VEHICLE_COUNT.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: (value: string | number) => {
            if (Number(value) < 1) {
              return {
                message: LOCATION_FORM_FIELDS.VEHICLE_COUNT.VALIDATION_MESSAGE,
              }
            }
          },
        },
      ],
      typeOfLocation: [
        {
          type: 'required',
          message: LOCATION_FORM_FIELDS.TYPE_OF_LOCATION.REQUIRED_MESSAGE,
        },
      ],
    }),
    []
  )

  const countries = useMemo(
    () =>
      countryOfResidence.map(
        ({ name, code }: { name: string; code: string }) => ({
          label: name,
          value: code,
        })
      ),
    []
  )

  return (
    <Form
      onSubmit={onSubmit}
      initValues={initValues}
      className={styles['location-form']}
      dataTestId="location-form"
      formId="location-form"
    >
      <div className={styles['location-form-row']}>
        <FormItem
          id={LOCATION_FORM_FIELDS.COUNTRIES.ID}
          className={styles['location-form-item']}
          rules={formRules.countries}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Multiselect
              items={countries}
              value={value}
              error={error}
              noSpaceForError
              placeholder={LOCATION_FORM_FIELDS.COUNTRIES.PLACEHOLDER}
              label={LOCATION_FORM_FIELDS.COUNTRIES.LABEL}
              isRequired
              dataTestId="location-form-countries"
              onChange={onChange}
              unitName={{
                plural: LOCATION_FORM_FIELDS.COUNTRIES.SINGLE_UNIT,
              }}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_FORM_FIELDS.LOCATION_COUNT.ID}
          className={styles['location-form-item']}
          rules={formRules.locationCount}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              error={error}
              spaceForError="auto"
              size="large"
              placeholder={LOCATION_FORM_FIELDS.LOCATION_COUNT.PLACEHOLDER}
              label={LOCATION_FORM_FIELDS.LOCATION_COUNT.LABEL}
              dataTestId="location-form-location-count"
              isRequired
              onChange={(value: string) => handleOnChange(value, onChange)}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['location-form-row']}>
        <FormItem
          id={LOCATION_FORM_FIELDS.TYPE_OF_LOCATION.ID}
          className={styles['location-form-item']}
          rules={formRules.typeOfLocation}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Multiselect
              items={locationTypes}
              value={value}
              error={error}
              noSpaceForError
              placeholder={LOCATION_FORM_FIELDS.TYPE_OF_LOCATION.PLACEHOLDER}
              label={LOCATION_FORM_FIELDS.TYPE_OF_LOCATION.LABEL}
              dataTestId="location-form-location-type"
              isRequired
              onChange={onChange}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_FORM_FIELDS.VEHICLE_COUNT.ID}
          className={styles['location-form-item']}
          rules={formRules.vehicleCount}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              error={error}
              spaceForError="auto"
              size="large"
              placeholder={LOCATION_FORM_FIELDS.VEHICLE_COUNT.PLACEHOLDER}
              label={LOCATION_FORM_FIELDS.VEHICLE_COUNT.LABEL}
              dataTestId="location-form-vehicle-count"
              isRequired
              onChange={(value: string) => handleOnChange(value, onChange)}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['location-form-btn-block']}>
        <div className={styles['location-form-btn']}>
          <OriginButton
            size="gigantic"
            variant="white-border"
            onClick={handleBack}
            label={BACK}
            dataTestId="location-back-btn"
          />
        </div>
        <div className={styles['location-form-btn']}>
          <OriginButton
            size="gigantic"
            label={NEXT}
            htmlType="submit"
            dataTestId="location-next-btn"
          />
        </div>
      </div>
    </Form>
  )
}

import { OTHER_INFO_FORM_FIELDS } from 'texts/otherInfoForm'

export const OTHER_INFO_INITIAL_VALUES = {
  [OTHER_INFO_FORM_FIELDS.API_TYPE.ID]: '',
}

export const OTHER_INFO_API_TYPE_ITEMS = [
  {
    value: 'Renteon',
    label: 'Renteon',
  },
  {
    value: 'Wheelsys',
    label: 'Wheelsys (Wheels)',
  },
  {
    value: 'Ratechain',
    label: 'Ratechain',
  },
  {
    value: 'TSD',
    label: 'TSD',
  },
  {
    value: 'DontHave',
    label: 'Don’t have',
  },
  {
    value: 'Other',
    label: 'Other type',
  },
]

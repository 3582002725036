import { EnvironmentVariablesService } from 'utils/env'

const mainAppUrl = EnvironmentVariablesService.getEnv('REACT_APP_MAIN_APP_URL')

export const NOTIFICATION_MESSAGE = {
  REGISTERED: 'Already registered.',
  REVIEWED: 'Your application is being reviewed.',
}

export const CONTACT_INFO_FORM_FIELDS = {
  COMPANY_NAME: {
    ID: 'companyName',
    PLACEHOLDER: 'Please enter a company name',
    LABEL: 'Company name',
    REQUIRED_MESSAGE: 'Please enter a company name.',
    VALIDATION_MESSAGE: 'Please use Latin characters only.',
  },
  PERSON_NAME: {
    ID: 'personName',
    PLACEHOLDER: "Please enter a contact person's name",
    LABEL: "Contact person's name",
    REQUIRED_MESSAGE: "Please enter a contact person's name.",
    VALIDATION_MESSAGE: 'Please use Latin characters only.',
  },
  PHONE_COR: {
    ID: 'phoneCor',
    REQUIRED_MESSAGE: 'Please select your country code.',
  },
  PHONE: {
    ID: 'phoneNumber',
    PLACEHOLDER: 'Please enter your phone number',
    LABEL: 'Phone number',
    REQUIRED_MESSAGE: 'Please enter your phone number.',
    VALIDATION_MESSAGE_MIN: 'Phone number cannot contain less than 3 digits.',
    VALIDATION_MESSAGE_MAX: 'Phone number cannot contain more than 20 digits.',
  },
  EMAIL: {
    ID: 'email',
    PLACEHOLDER: 'Please enter your email',
    LABEL: 'Email',
    REQUIRED_MESSAGE: 'Please enter your email.',
    VALIDATION_MESSAGE: 'Please enter a valid email.',
  },
  PASSWORD: {
    ID: 'password',
    PLACEHOLDER: 'Please enter your password',
    LABEL: 'Password',
    REQUIRED_MESSAGE: 'Please enter your password.',
    VALIDATION_MESSAGE_MIN: 'Please enter at least 8 characters.',
    VALIDATION_MESSAGE: 'Password does not meet the requirements.',
  },
  CONFIRM_PASSWORD: {
    ID: 'confirmPassword',
    PLACEHOLDER: 'Please confirm your password',
    LABEL: 'Confirm password',
    VALIDATION_MESSAGE: 'The passwords you entered do not match.',
  },
  AGREE_WITH_RULES: {
    ID: 'agreeWithRules',
    LABEL: `By checking this box you agree with our <a href="${mainAppUrl}/en/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="${mainAppUrl}/en/privacy-policy" target="_blank">Privacy Policy</a>`,
    REQUIRED_MESSAGE:
      'Please confirm that you agree with our Terms & Conditions and Privacy Policy.',
  },
}

export const passwordScaleLabels = {
  label: 'Your password must have',
  labelWeak: 'Weak',
  labelMedium: 'Medium',
  labelStrong: 'Strong',
}

export const PASSWORD_RULES = {
  MIN_CHARACTERS: 'At least 8 characters, no spaces',
  UPPER_AND_LOWER_CASE: 'Upper & lowercase Latin letters',
  ONE_NUMBER: 'At least one number',
}

export const PHONE_NUMBER_LABELS = {
  OF: 'of',
  SEARCH: 'Search for a country',
  SAVE: 'save',
}

export const RESEND_EMAIL_MODAL = {
  TITLE: 'Confirm your email address',
  DESCRIPTION:
    'Your email address is already in use but it has not been confirmed yet. To complete the registration, please check your inbox, locate the confirmation email and click on the link, or resend confirmation email to receive a new link.',
  BUTTON_LABEL: 'Resend confirmation email',
}

export const SUCCESS_EMAIL_SENT_MODAL = {
  TITLE: 'Check your inbox',
  DESCRIPTION: (email: string) =>
    `We have sent you an email to <mark>${email}</mark>. To complete the registration, please click on the confirmation link in the email.`,
}

import { Form, FormItem, OriginButton, Select } from '@frontend/design_system'
import { useMemo, useState } from 'react'
import {
  OTHER_INFO_API_TYPE_ITEMS,
  OTHER_INFO_INITIAL_VALUES,
} from './OtherInfoForm.constants'
import { FormItemChildren } from 'types/formItem'
import { resetFormValues, setCurrentStep } from 'redux/common/slice'
import { useDispatch, useSelector } from 'react-redux'
import { STEPS } from 'modules/BecomePartnerForm/BecomePartnerForm.constants'
import {
  OTHER_INFO_FORM_FIELDS,
  SUCCESS_EMAIL_SENT_MODAL,
} from 'texts/otherInfoForm'
import { BACK, SUBMIT } from 'texts/form'
import { api } from 'api'
import { SuccessModal } from '../SuccessModal'
import {
  contactInfoFormSelector,
  locationAndFleetFormSelector,
} from 'redux/common/selectors'
import { setNotificationMessage } from 'redux/notifications/slice'
import { ErrorResponse } from 'api/types'
import { SuccessEmailSentModal } from '../SuccessEmailSentModal'
import styles from './index.module.scss'

export const OtherInfoForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [email, setEmail] = useState('')
  const locationFormValues = useSelector(locationAndFleetFormSelector)
  const contactInfoValues = useSelector(contactInfoFormSelector)

  const dispatch = useDispatch()

  const onSubmit = async (
    values: Record<string, string>,
    validate: boolean
  ) => {
    if (validate) {
      setIsLoading(true)
      try {
        const formValues = {
          companyName: contactInfoValues?.companyName,
          contactName: contactInfoValues?.personName,
          contactEmail: contactInfoValues?.email,
          phone: contactInfoValues?.phoneNumber,
          phoneCountryCode: contactInfoValues?.phoneCor?.code,
          phoneAreaCode: String(contactInfoValues?.phoneCor?.phoneCode),
          countriesWithFleets: locationFormValues?.countries.map(
            (el) => el.value
          ),
          vehiclesCount: locationFormValues?.vehicleCount,
          apiIntegrationType: values.apiType,
          locationTypes: locationFormValues?.typeOfLocation.map(
            (el) => el.label
          ),
          locationsCount: locationFormValues?.locationCount,
          password: contactInfoValues?.password,
        }
        const response = await api.sendApplication(formValues)
        if (response.data.applicationInfo.companyId) {
          setEmail(response.data.applicationInfo.contactEmail)
        } else {
          setApplicationId(String(response.data.applicationInfo.applicationId))
        }
      } catch (error) {
        dispatch(
          setNotificationMessage({
            notificationMessage: (error as ErrorResponse).responseStatus
              ?.message,
            type: 'error',
          })
        )
      }
      setIsLoading(false)
    }
  }

  const resetForm = () => {
    dispatch(setCurrentStep(STEPS.contactInfo))
    dispatch(resetFormValues())
  }

  const handleBack = () => {
    dispatch(setCurrentStep(STEPS.locationAndFleet))
  }

  const rules = useMemo(
    () => ({
      [OTHER_INFO_FORM_FIELDS.API_TYPE.ID]: [
        {
          type: 'required',
          message: OTHER_INFO_FORM_FIELDS.API_TYPE.REQUIRED_MESSAGE,
        },
      ],
    }),
    []
  )

  const handleCloseApiModal = () => {
    setApplicationId('')
    resetForm()
  }

  const handleCloseStaticModal = () => {
    setEmail('')
    resetForm()
  }

  return (
    <>
      {applicationId && (
        <SuccessModal
          onClose={handleCloseApiModal}
          applicationId={applicationId}
        />
      )}
      {email && (
        <SuccessEmailSentModal
          onClose={handleCloseStaticModal}
          title={SUCCESS_EMAIL_SENT_MODAL.TITLE}
          description={SUCCESS_EMAIL_SENT_MODAL.DESCRIPTION(email)}
        />
      )}
      <Form
        onSubmit={onSubmit}
        initValues={OTHER_INFO_INITIAL_VALUES}
        className={styles['other-info-form']}
      >
        <FormItem
          id={OTHER_INFO_FORM_FIELDS.API_TYPE.ID}
          rules={rules[OTHER_INFO_FORM_FIELDS.API_TYPE.ID]}
          className={styles['other-info-form-api-type']}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Select
              selectedValue={value}
              size="large"
              error={error}
              noSpaceForError
              placeholder={OTHER_INFO_FORM_FIELDS.API_TYPE.PLACEHOLDER}
              onChange={onChange}
              label={OTHER_INFO_FORM_FIELDS.API_TYPE.LABEL}
              items={OTHER_INFO_API_TYPE_ITEMS}
              isRequired
              dataTestId="other-info-form-api-type"
            />
          )}
        </FormItem>
        <div className={styles['other-info-form-actions']}>
          <div className={styles['other-info-form-action']}>
            <OriginButton
              size="gigantic"
              label={BACK}
              variant="white-border"
              onClick={handleBack}
              dataTestId="other-info-back-button"
              typographyName="Button1"
              loading={isLoading}
            />
          </div>
          <div className={styles['other-info-form-action']}>
            <OriginButton
              size="gigantic"
              label={SUBMIT}
              htmlType="submit"
              typographyName="Button1"
              loading={isLoading}
              dataTestId="other-info-form-submit"
            />
          </div>
        </div>
      </Form>
    </>
  )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles.module.scss'
import { App } from './App'
import { Provider } from 'react-redux'
import { store } from 'redux/store'
import { GLOBAL_MODALS } from '@frontend/design_system/src/constants'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
    <div id={GLOBAL_MODALS} />
  </Provider>
)

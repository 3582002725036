export const TITLE = 'Build your business with us'
export const SUBTITLE = 'HOW IT WORKS'

export const STEPS = [
  {
    title: 'Sign up',
    desc: 'Complete the simple online form to apply as a partner.',
  },
  {
    title: 'Upload your car',
    desc: 'Start receiving bookings from customers worldwide and service them through our integrated platform.',
  },
  {
    title: 'Receive bookings and Get paid',
    desc: 'After completing the services, receive prompt and secure payments.',
  },
]

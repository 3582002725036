export const OTHER_INFO_FORM_FIELDS = {
  API_TYPE: {
    ID: 'apiType',
    LABEL: 'What type of API integration do you have?',
    PLACEHOLDER: 'Please select API type',
    REQUIRED_MESSAGE: 'Please select your API integration.',
  },
}

export const SUCCESS_MODAL = {
  TITLE: 'Your application has been submitted for review',
  APPLICATION_ID: (applicationId: string) =>
    `Your <mark>Application ID is ${applicationId}.</mark>`,
  THANK_YOU: 'Thank you for your interest in becoming our partner.',
  PLEASE_WAIT:
    'We will get back to you as soon as we have finished reviewing your application.',
  CONTACT_US:
    'You will need to provide it if you contact us about your application.',
  FOOTER:
    'Should you have any questions, please email us at <a href="mailto: marketplace@bookinggroup.com">marketplace@bookinggroup.com</a>',
}

export const SUCCESS_EMAIL_SENT_MODAL = {
  TITLE: 'You’re almost done',
  CHECK_EMAIL: 'Please check your email.',
  DESCRIPTION: (email: string) =>
    `We appreciate your interest in becoming our partner. To complete your registration, please click the confirmation link we sent to <mark>${email}</mark>.`,
}

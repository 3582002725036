import {
  Modal,
  OriginButton,
  Typography,
  LazyImage,
} from '@frontend/design_system'
import SuccessSrc from 'img/Success.webp'
import { api } from 'api'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { ErrorResponse } from 'api/types'
import { ResendEmailModalProps } from './ResendEmailModal.types'
import styles from './index.module.scss'
import { useState } from 'react'
import { SuccessEmailSentModal } from 'modules/BecomePartnerForm/components/SuccessEmailSentModal'
import {
  RESEND_EMAIL_MODAL,
  SUCCESS_EMAIL_SENT_MODAL,
} from 'texts/contactInfoForm'

export const ResendEmailModal = ({ onClose, email }: ResendEmailModalProps) => {
  const dispatch = useDispatch()
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    try {
      await api.resendConfirmationLink(email)
      setSuccessModalOpen(true)
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus?.message,
          type: 'error',
        })
      )
    }
    setLoading(false)
  }

  return isSuccessModalOpen ? (
    <SuccessEmailSentModal
      title={SUCCESS_EMAIL_SENT_MODAL.TITLE}
      description={SUCCESS_EMAIL_SENT_MODAL.DESCRIPTION(email)}
      onClose={onClose}
    />
  ) : (
    <Modal
      position="center-horizontal"
      onClose={onClose}
      isDelimiter
      offsetVariant="none"
      title={RESEND_EMAIL_MODAL.TITLE}
      headerSize="large"
      dataTestId="resend-email-modal"
    >
      <div className={styles.content}>
        <Typography color="grey500" name="Button1">
          {RESEND_EMAIL_MODAL.DESCRIPTION}
        </Typography>
        <LazyImage
          src={SuccessSrc}
          width={96}
          height={96}
          className={styles.image}
        />
        <div className={styles['button-wrapper']}>
          <OriginButton
            onClick={handleClick}
            size="large"
            loading={isLoading}
            label={RESEND_EMAIL_MODAL.BUTTON_LABEL}
            dataTestId="resend-email-confirmation-button"
          />
        </div>
      </div>
    </Modal>
  )
}

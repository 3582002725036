import { useDispatch, useSelector } from 'react-redux'
import { Notification } from '@frontend/design_system'
import { notificationSelector } from 'redux/notifications/selectors'
import { hideNotification } from 'redux/notifications/slice'

export const GeneralNotification = () => {
  const dispatch = useDispatch()
  const { notificationMessage, type, timer, additionalMessage } =
    useSelector(notificationSelector)

  if (!notificationMessage) {
    return null
  }

  const onClose = () => dispatch(hideNotification())

  return (
    <Notification
      notificationMessage={notificationMessage}
      onClose={onClose}
      top={80}
      type={type}
      right="16px"
      timer={timer}
      additionalMessage={additionalMessage}
    />
  )
}

const ENVIRONMENT_VARIABLES = {
  NODE_ENV: 'NODE_ENV',
  REACT_APP_MARKETPLACE_URL: 'REACT_APP_MARKETPLACE_URL',
  REACT_APP_AWS_CDN_VIDEO_PATH: 'REACT_APP_AWS_CDN_VIDEO_PATH',
  REACT_APP_BACKEND_URL: 'REACT_APP_BACKEND_URL',
  REACT_APP_MAIN_APP_URL: 'REACT_APP_MAIN_APP_URL',
}

class EnvironmentVariables {
  getEnv = (env: keyof typeof ENVIRONMENT_VARIABLES, required?: boolean) => {
    if (process.env[env]) {
      return process.env[env]
    }

    if (required) {
      console.error(`Environment variable ${env} is required`)
      process.exit(1)
    }

    return process.env[env]
  }
}

export const EnvironmentVariablesService = new EnvironmentVariables()

export const isDev =
  EnvironmentVariablesService.getEnv('NODE_ENV') === 'development'

import {
  Form,
  FormItem,
  Input,
  Checkbox,
  Phone,
  OriginButton,
  InputPassword,
  LazyImage,
  PasswordChecklist,
} from '@frontend/design_system'
import { useDispatch, useSelector } from 'react-redux'
import { contactInfoFormSelector } from 'redux/common/selectors'
import { useMemo, useState } from 'react'
import compassIconSrc from 'img/grey400_compass_20x20.webp'
import { countryOfResidence } from '@frontend/design_system/src/constants'
import { setContactFormValues, setCurrentStep } from 'redux/common/slice'
import { STEPS } from 'modules/BecomePartnerForm/BecomePartnerForm.constants'
import { FormItemChildren } from 'types/formItem'
import styles from './index.module.scss'
import {
  allSymbolsAndEnglishLettersRegex,
  minimum8Regex,
  must1DigitalRegex,
  must1LowerAndUpperRegex,
  passwordRegex,
} from 'constants/regexSamples'
import { FormChildren } from 'types/form'
import {
  CONTACT_INFO_FORM_FIELDS,
  NOTIFICATION_MESSAGE,
  PASSWORD_RULES,
  passwordScaleLabels,
  PHONE_NUMBER_LABELS,
} from 'texts/contactInfoForm'
import { NEXT } from 'texts/form'
import { api } from 'api'
import { setNotificationMessage } from 'redux/notifications/slice'
import { APPLICATION_STATUS } from './ContactInfoForm.constants'
import { ResendEmailModal } from './components/ResendEmailModal'
import { ContactInfoFormValues } from './ContactInfoForm.types'
import { ErrorResponse } from 'api/types'

export const ContactInfoForm = () => {
  const contactInfoValues = useSelector(contactInfoFormSelector)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [formKey, setFormKey] = useState(0)

  const onSubmit = async (data: ContactInfoFormValues, validate: boolean) => {
    if (validate) {
      try {
        const checkEmailResponse = await api.validateEmail(data.email)
        const { status } = checkEmailResponse.data

        if (status === APPLICATION_STATUS.UNCONFIRMED) {
          setEmail(data.email)
          return
        } else if (
          [
            APPLICATION_STATUS.DEFERRED,
            APPLICATION_STATUS.IN_REVIEW,
            APPLICATION_STATUS.REGISTERED,
            APPLICATION_STATUS.APPROVED,
          ].includes(status)
        ) {
          const notificationMessage =
            status === APPLICATION_STATUS.REGISTERED ||
            status === APPLICATION_STATUS.APPROVED
              ? NOTIFICATION_MESSAGE.REGISTERED
              : NOTIFICATION_MESSAGE.REVIEWED

          dispatch(
            setNotificationMessage({
              notificationMessage,
              type: 'error',
            })
          )
        } else {
          dispatch(setContactFormValues(data))
          dispatch(setCurrentStep(STEPS.locationAndFleet))
        }
      } catch (e) {
        dispatch(
          setNotificationMessage({
            notificationMessage: (e as ErrorResponse).responseStatus?.message,
            type: 'error',
          })
        )
      }
    }

    return false
  }

  const initValues = useMemo(
    () => ({
      [CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.ID]:
        contactInfoValues?.companyName || '',
      [CONTACT_INFO_FORM_FIELDS.PERSON_NAME.ID]:
        contactInfoValues?.personName || '',
      [CONTACT_INFO_FORM_FIELDS.EMAIL.ID]: contactInfoValues?.email || '',
      [CONTACT_INFO_FORM_FIELDS.PHONE.ID]: contactInfoValues?.phoneNumber || '',
      [CONTACT_INFO_FORM_FIELDS.PHONE_COR.ID]: contactInfoValues?.phoneCor,
      [CONTACT_INFO_FORM_FIELDS.PASSWORD.ID]: contactInfoValues?.password || '',
      [CONTACT_INFO_FORM_FIELDS.CONFIRM_PASSWORD.ID]:
        contactInfoValues?.confirmPassword || '',
      [CONTACT_INFO_FORM_FIELDS.AGREE_WITH_RULES.ID]:
        contactInfoValues?.agreeWithRules || false,
    }),
    [contactInfoValues]
  )

  const passwordRules = useMemo(
    () => [
      {
        type: 'required',
        message: CONTACT_INFO_FORM_FIELDS.PASSWORD.REQUIRED_MESSAGE,
      },
      {
        type: 'min',
        value: 8,
        message: CONTACT_INFO_FORM_FIELDS.PASSWORD.VALIDATION_MESSAGE_MIN,
      },
      {
        type: 'pattern',
        value: passwordRegex,
        message: CONTACT_INFO_FORM_FIELDS.PASSWORD.VALIDATION_MESSAGE,
      },
    ],
    []
  )

  const passwordScaleRules = useMemo(
    () => [
      {
        label: PASSWORD_RULES.MIN_CHARACTERS,
        rule: minimum8Regex,
      },
      {
        label: PASSWORD_RULES.UPPER_AND_LOWER_CASE,
        rule: must1LowerAndUpperRegex,
      },
      {
        label: PASSWORD_RULES.ONE_NUMBER,
        rule: must1DigitalRegex,
      },
    ],
    []
  )

  const confirmPasswordRules = useMemo(
    () => [
      ...passwordRules,
      {
        type: 'equal',
        value: 'password',
        message: CONTACT_INFO_FORM_FIELDS.CONFIRM_PASSWORD.VALIDATION_MESSAGE,
      },
    ],
    []
  )

  const formRules = useMemo(
    () => ({
      companyName: [
        {
          type: 'required',
          message: CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.REQUIRED_MESSAGE,
        },
        {
          type: 'pattern',
          value: allSymbolsAndEnglishLettersRegex,
          message: CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.VALIDATION_MESSAGE,
        },
      ],
      personName: [
        {
          type: 'required',
          message: CONTACT_INFO_FORM_FIELDS.PERSON_NAME.REQUIRED_MESSAGE,
        },
        {
          type: 'pattern',
          value: allSymbolsAndEnglishLettersRegex,
          message: CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.VALIDATION_MESSAGE,
        },
      ],
      email: [
        {
          type: 'required',
          message: CONTACT_INFO_FORM_FIELDS.EMAIL.REQUIRED_MESSAGE,
        },
        {
          type: 'email',
          message: CONTACT_INFO_FORM_FIELDS.EMAIL.VALIDATION_MESSAGE,
        },
      ],
      phoneNumber: [
        {
          type: 'required',
          message: CONTACT_INFO_FORM_FIELDS.PHONE.REQUIRED_MESSAGE,
        },
        {
          type: 'min',
          value: 3,
          message: CONTACT_INFO_FORM_FIELDS.PHONE.VALIDATION_MESSAGE_MIN,
        },
        {
          type: 'max',
          value: 20,
          message: CONTACT_INFO_FORM_FIELDS.PHONE.VALIDATION_MESSAGE_MAX,
        },
      ],
      agreeWithRules: [
        {
          type: 'required',
          message: CONTACT_INFO_FORM_FIELDS.AGREE_WITH_RULES.REQUIRED_MESSAGE,
        },
      ],
      phoneCor: [
        {
          type: 'required',
          message: CONTACT_INFO_FORM_FIELDS.PHONE_COR.REQUIRED_MESSAGE,
        },
      ],
      empty: [],
    }),
    []
  )

  const passwordScale = {
    ...passwordScaleLabels,
    rules: passwordScaleRules,
  }

  const handleCloseResendModal = () => {
    setEmail('')
    setFormKey(formKey + 1)
  }

  return (
    <>
      {email && (
        <ResendEmailModal onClose={handleCloseResendModal} email={email} />
      )}
      <Form
        onSubmit={onSubmit}
        initValues={initValues}
        key={formKey}
        className={styles['contact-info-form']}
        dataTestId="contact-info-form"
        formId="contact-info-form"
      >
        {({ values }: FormChildren) => (
          <>
            <div className={styles['contact-info-form-row']}>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.ID}
                className={styles['contact-info-form-item']}
                rules={formRules.companyName}
              >
                {({ value, onChange, error, onBlur }: FormItemChildren) => (
                  <Input
                    value={value}
                    placeholder={
                      CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.PLACEHOLDER
                    }
                    size="large"
                    onChange={onChange}
                    label={CONTACT_INFO_FORM_FIELDS.COMPANY_NAME.LABEL}
                    iconLeft={
                      <LazyImage src={compassIconSrc} width={20} height={20} />
                    }
                    iconSize="small"
                    spaceForError="auto"
                    error={error}
                    onBlur={onBlur}
                    isRequired
                  />
                )}
              </FormItem>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.PERSON_NAME.ID}
                className={styles['contact-info-form-item']}
                rules={formRules.personName}
              >
                {({ value, onChange, error, onBlur }: FormItemChildren) => (
                  <Input
                    value={value}
                    placeholder={
                      CONTACT_INFO_FORM_FIELDS.PERSON_NAME.PLACEHOLDER
                    }
                    size="large"
                    onChange={onChange}
                    iconLeft="UserIcon"
                    label={CONTACT_INFO_FORM_FIELDS.PERSON_NAME.LABEL}
                    iconSize="small"
                    spaceForError="auto"
                    error={error}
                    onBlur={onBlur}
                    isRequired
                  />
                )}
              </FormItem>
            </div>
            <div className={styles['contact-info-form-row']}>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.EMAIL.ID}
                className={styles['contact-info-form-item']}
                rules={formRules.email}
              >
                {({ value, error, onChange, onBlur }: FormItemChildren) => (
                  <Input
                    value={value}
                    placeholder={CONTACT_INFO_FORM_FIELDS.EMAIL.PLACEHOLDER}
                    size="large"
                    error={error}
                    onChange={onChange}
                    label={CONTACT_INFO_FORM_FIELDS.EMAIL.LABEL}
                    iconLeft="MailIcon"
                    iconSize="small"
                    autocomplete="email"
                    spaceForError="auto"
                    isRequired
                    onBlur={onBlur}
                  />
                )}
              </FormItem>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.PHONE.ID}
                className={styles['contact-info-form-item']}
                rules={formRules.phoneNumber}
              >
                {({
                  value: number,
                  onChange: changeNumber,
                  error,
                  onBlur,
                }: FormItemChildren) => (
                  <FormItem
                    id={CONTACT_INFO_FORM_FIELDS.PHONE_COR.ID}
                    rules={
                      number?.length ? formRules.phoneCor : formRules.empty
                    }
                  >
                    {({
                      value: cor,
                      onChange: changeCor,
                      error: corError,
                    }: FormItemChildren) => (
                      <Phone
                        label={CONTACT_INFO_FORM_FIELDS.PHONE.LABEL}
                        placeholder={CONTACT_INFO_FORM_FIELDS.PHONE.PLACEHOLDER}
                        number={number}
                        changeNumber={changeNumber}
                        cor={cor}
                        changeCOR={changeCor}
                        corList={countryOfResidence}
                        locales={{
                          ofLabel: PHONE_NUMBER_LABELS.OF,
                          searchPlaceholder: PHONE_NUMBER_LABELS.SEARCH,
                          save: PHONE_NUMBER_LABELS.SAVE,
                        }}
                        error={error || corError}
                        isRequired
                        onBlur={onBlur}
                      />
                    )}
                  </FormItem>
                )}
              </FormItem>
            </div>
            <div className={styles['contact-info-form-row']}>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.PASSWORD.ID}
                rules={passwordRules}
              >
                {({ value, error, onChange }: FormItemChildren) => (
                  <InputPassword
                    size="large"
                    value={value}
                    placeholder={CONTACT_INFO_FORM_FIELDS.PASSWORD.PLACEHOLDER}
                    error={error}
                    withCompare
                    label={CONTACT_INFO_FORM_FIELDS.PASSWORD.LABEL}
                    onChange={onChange}
                    isRequired
                    passwordScale={passwordScale}
                    dataTestId="contact-form-password"
                  />
                )}
              </FormItem>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.CONFIRM_PASSWORD.ID}
                rules={confirmPasswordRules}
              >
                {({ value, error, onChange }: FormItemChildren) => (
                  <InputPassword
                    size="large"
                    value={value}
                    placeholder={
                      CONTACT_INFO_FORM_FIELDS.CONFIRM_PASSWORD.PLACEHOLDER
                    }
                    error={error}
                    isRequired
                    label={CONTACT_INFO_FORM_FIELDS.CONFIRM_PASSWORD.LABEL}
                    onChange={onChange}
                    dataTestId="contact-form-confirm-password"
                  />
                )}
              </FormItem>
            </div>
            <div className={styles['password-checklist-wrapper']}>
              <PasswordChecklist
                value={values.password as string}
                passwordScale={passwordScale}
              />
            </div>
            <div className={styles['contact-info-form-checkbox-block']}>
              <FormItem
                id={CONTACT_INFO_FORM_FIELDS.AGREE_WITH_RULES.ID}
                className={styles['contact-info-form-item-checkbox']}
                rules={formRules.agreeWithRules}
              >
                {({ value, onChange, error }: FormItemChildren) => (
                  <Checkbox
                    labelHtml={CONTACT_INFO_FORM_FIELDS.AGREE_WITH_RULES.LABEL}
                    onChange={onChange}
                    checked={value}
                    isRequired
                    borderVariant="grey"
                    labelColor="grey500"
                    error={!!error}
                    errorMessage={error}
                    dataTestId="contact-form-agreement-checkbox"
                  />
                )}
              </FormItem>
            </div>
            <div className={styles['contact-info-form-btn']}>
              <OriginButton
                size="gigantic"
                label={NEXT}
                typographyName="subtitleWBold"
                htmlType="submit"
                dataTestId="contact-form-submit-button"
              />
            </div>
          </>
        )}
      </Form>
    </>
  )
}

import { ReactComponent as PartnersSvg } from 'img/partners.svg'
import { useEffect, useRef } from 'react'
import { blue20, blue700 } from './constants'

export const PartnersBlock = () => {
  const partnersSvgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const logos = partnersSvgRef.current?.querySelectorAll('.logo')

    const handleMouseEnter = (line: HTMLElement, logo: HTMLElement) => {
      line.style.stroke = blue700

      const rects = logo.querySelectorAll('rect')

      rects?.forEach((el) => {
        el.style.transition = 'transform 0.3s ease, fill 0.3s ease'
        el.style.transform = 'scale(1.1)'
        el.style.transformBox = 'fill-box'
        el.style.transformOrigin = 'center'
      })
    }

    const handleMouseLeave = (line: HTMLElement, logo: HTMLElement) => {
      line.style.stroke = blue20

      const rects = logo.querySelectorAll('rect')

      rects?.forEach((el) => {
        el.style.transform = 'scale(1)'
      })
    }

    logos?.forEach((logo, id) => {
      const lineId = `line-${id}`
      const line = partnersSvgRef.current?.getElementById(lineId) as HTMLElement

      const onMouseEnter = () => handleMouseEnter(line, logo as HTMLElement)
      const onMouseLeave = () => handleMouseLeave(line, logo as HTMLElement)

      logo.addEventListener('mouseenter', onMouseEnter)
      logo.addEventListener('mouseleave', onMouseLeave)

      return () => {
        logo.removeEventListener('mouseenter', onMouseEnter)
        logo.removeEventListener('mouseleave', onMouseLeave)
      }
    })
  }, [])

  return <PartnersSvg ref={partnersSvgRef} data-testid="partners-block" />
}

import footerLogo from 'img/footerLogo.webp'
import { Wrapper, Typography, LazyImage } from '@frontend/design_system'
import { FOOTER_TRADEMARK } from '../../texts/footer'
import styles from './index.module.scss'

export const Footer = () => (
  <div className={styles.footer} data-testid="footer">
    <Wrapper className={styles.wrapper}>
      <LazyImage src={footerLogo} className={styles.logo} layout="fill" />
      <Typography
        name="body2WMedium"
        color="whiteMaster"
        className={styles.trademark}
      >
        {FOOTER_TRADEMARK}
      </Typography>
    </Wrapper>
  </div>
)

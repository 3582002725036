export const LOCATION_FORM_FIELDS = {
  COUNTRIES: {
    ID: 'countries',
    LABEL: 'Country/ies you have a fleet in',
    PLACEHOLDER: 'Select country',
    PLURAL_UNIT: 'countries',
    SINGLE_UNIT: 'country',
    REQUIRED_MESSAGE: 'Please select country.',
  },
  LOCATION_COUNT: {
    ID: 'locationCount',
    LABEL: 'Number of locations you operate in',
    REQUIRED_MESSAGE: 'Please enter number of locations.',
    PLACEHOLDER: 'Enter number of locations',
    VALIDATION_MESSAGE: 'Please enter at least 1 location.',
  },
  TYPE_OF_LOCATION: {
    ID: 'typeOfLocation',
    LABEL: 'Type of location',
    REQUIRED_MESSAGE: 'Please select type of location.',
    PLACEHOLDER: 'Select location',
  },
  VEHICLE_COUNT: {
    ID: 'vehicleCount',
    LABEL: 'Number of cars in your fleet (approximate)',
    REQUIRED_MESSAGE: 'Please enter number of cars.',
    PLACEHOLDER: 'Enter number of cars',
    VALIDATION_MESSAGE: 'Please enter at least 1 car.',
  },
}

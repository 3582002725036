import { Modal, LazyImage, Typography } from '@frontend/design_system'
import noSubscriptionBackground from 'img/no-promotion-bg.webp'
import { SuccessModalProps } from './SuccessModal.types'
import styles from './index.module.scss'
import { SUCCESS_MODAL } from 'texts/otherInfoForm'

export const SuccessModal = ({ onClose, applicationId }: SuccessModalProps) => (
  <Modal
    onClose={onClose}
    position="center-horizontal"
    invisibleHeader={{ desktop: true, tablet: true, mobile: true }}
    offsetVariant="none"
    fullWidth={{
      desktop: false,
      tablet: false,
      mobile: false,
    }}
    closeVariant="secondary"
    dataTestId="become-partner-form-success-modal"
  >
    <div className={styles['become-partner-success-modal']}>
      <div className={styles['become-partner-success-modal-header']}>
        <LazyImage
          src={noSubscriptionBackground}
          className={styles['become-partner-success-background-image']}
          layout="fill"
          objectFit="cover"
        />
        <Typography
          name="H4"
          Tag="h4"
          className={styles['become-partner-success-modal-title']}
          nameForMobile="subtitleWBold"
          color="whiteMaster"
        >
          {SUCCESS_MODAL.TITLE}
        </Typography>
      </div>
      <div className={styles['become-partner-success-modal-content']}>
        <Typography
          name="subtitleWBold"
          Tag="h5"
          className={styles['become-partner-success-modal-title']}
        >
          {SUCCESS_MODAL.THANK_YOU}
        </Typography>
        <Typography name="body1WMedium" color="grey500">
          {SUCCESS_MODAL.PLEASE_WAIT}
        </Typography>
        <Typography
          name="Button1"
          color="grey500"
          markColor="grey900Master"
          markName="body1WBold"
          html={SUCCESS_MODAL.APPLICATION_ID(applicationId)}
        />
        <Typography name="body1WMedium" color="grey500">
          {SUCCESS_MODAL.CONTACT_US}
        </Typography>
        <Typography
          name="body1WMedium"
          color="grey500"
          html={SUCCESS_MODAL.FOOTER}
        />
      </div>
    </div>
  </Modal>
)

import { Typography, Steps } from '@frontend/design_system'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_LABELS, STEPS } from './BecomePartnerForm.constants'
import { ContactInfoForm } from './components/ContactInfoForm'
import { OtherInfoForm } from './components/OtherInfoForm'
import { currentStepSelector } from 'redux/common/selectors'
import { setCurrentStep } from 'redux/common/slice'
import { LocationForm } from './components/LocationForm'
import { forwardRef, Ref } from 'react'
import { SUBTITLE, TITLE } from 'texts/form'
import styles from './index.module.scss'

export const FORM_SETTINGS = {
  [STEPS.contactInfo]: <ContactInfoForm />,
  [STEPS.locationAndFleet]: <LocationForm />,
  [STEPS.otherInfo]: <OtherInfoForm />,
}

export const BecomePartnerForm = forwardRef(({}, ref: Ref<HTMLDivElement>) => {
  const currentStep = useSelector(currentStepSelector)
  const dispatch = useDispatch()

  const steps = Object.values(STEPS)

  const stepsSettings = steps.map((step) => ({
    label: STEP_LABELS[step],
    onClick: () => {
      if (currentStep > step) {
        dispatch(setCurrentStep(step))
      }
    },
  }))

  return (
    <div className={styles['become-partner-form-wrapper']} ref={ref}>
      <Typography
        name="body2WBold"
        color="blue700"
        className={styles['become-partner-form-subtitle']}
        Tag="div"
      >
        {SUBTITLE}
      </Typography>
      <Typography
        name="h3WBold"
        nameForTablet="h4WBold"
        Tag="h2"
        className={styles['become-partner-form-title']}
      >
        {TITLE}
      </Typography>
      <section
        className={styles['become-partner-form']}
        data-testid="become-partner-form"
      >
        <div className={styles['become-partner-form-steps']}>
          <Steps
            variant="become-partner"
            currentStep={currentStep}
            list={stepsSettings}
          />
        </div>
        <div className={styles['become-partner-form-form']}>
          <div className={styles['become-partner-form-form-inner']}>
            {FORM_SETTINGS[currentStep]}
          </div>
        </div>
      </section>
    </div>
  )
})

import React from 'react'
import { Typography } from '@frontend/design_system'
import styles from './index.module.scss'
import { PartnersBlock } from './components/PartnersBlock'
import { STEPS, SUBTITLE, TITLE } from 'texts/howItWorks'

export const HowItWorks = () => (
  <div className={styles.wrapper} data-testid="how-it-works-block">
    <Typography name="body2WBold" color="blue700">
      {SUBTITLE}
    </Typography>
    <Typography className={styles.title} name="h3WBold">
      {TITLE}
    </Typography>
    <div className={styles.content}>
      <ul className={styles.steps}>
        {STEPS.map(({ title, desc }, index) => {
          const step = index + 1

          return (
            <React.Fragment key={step}>
              <li
                className={styles['steps-step']}
                data-testid={`steps-step-${step}`}
              >
                <Typography
                  name="body2WBold"
                  className={styles['steps-circle']}
                  dataTestId="steps-step"
                  color="blue700"
                >
                  {step}
                </Typography>
                <div className={styles['li-content']}>
                  <Typography
                    Tag="p"
                    name="subtitleWBold"
                    nameForTablet="Button2"
                    className={styles['li-content-title']}
                  >
                    {title}
                  </Typography>
                  <Typography Tag="p" name="body1WMedium" color="grey500">
                    {desc}
                  </Typography>
                </div>
              </li>
            </React.Fragment>
          )
        })}
      </ul>
      <div className={styles['partner-block-wrapper']}>
        <PartnersBlock />
      </div>
    </div>
  </div>
)

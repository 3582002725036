import { ApplicationStatus } from 'api/types'

export const APPLICATION_STATUS: { [key: string]: ApplicationStatus } = {
  REGISTERED: 'Registered',
  UNCONFIRMED: 'Unconfirmed',
  NEW: 'New',
  IN_REVIEW: 'InReview',
  DEFERRED: 'Deferred',
  APPROVED: 'Approved',
}

export interface HttpResponse<T, E = ErrorResponse> extends Response {
  data: T
  error: E
}

export enum ContentType {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
}

export type EmptyResponse = object

export type ErrorResponse = {
  responseStatus: ResponseStatus
}

type ResponseStatus = {
  errorCode: string
  message: string
  stackStrace: string
}

export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export type LocationType = {
  name: string
  id: string
}

export type ApplicationStatus =
  | 'Unconfirmed'
  | 'New'
  | 'InReview'
  | 'Registered'
  | 'Deferred'
  | 'Approved'

export type SendApplicationResponse = {
  applicationInfo: Required<SendApplicationRequest> & {
    applicationId: number
    status: string
    submission: string
    processTime: string
    companyId?: string
  }
}

export type SendApplicationRequest = {
  companyName?: string
  contactName?: string
  contactEmail?: string
  phone?: string
  phoneCountryCode?: string
  phoneAreaCode?: string
  countriesWithFleets?: string[]
  vehiclesCount?: string
  apiIntegrationType?: string
  locationTypes?: string[]
  locationsCount?: string
  password?: string
}

import { OriginButton, Wrapper, LazyImage } from '@frontend/design_system'
import Logo from 'img/BookingLogo.webp'
import { EnvironmentVariablesService } from 'utils/env'
import { SIGN_IN_LABEL } from '../../texts/header'
import styles from './index.module.scss'

export const Header = () => {
  const handleRedirectToMarketplace = () => {
    window.location.href = EnvironmentVariablesService.getEnv(
      'REACT_APP_MARKETPLACE_URL'
    ) as string
  }

  const handleRedirectToEconomyBookings = () => {
    window.location.href = EnvironmentVariablesService.getEnv(
      'REACT_APP_MAIN_APP_URL'
    ) as string
  }

  return (
    <div className={styles.header} data-testid="header">
      <Wrapper>
        <div className={styles['header-content']}>
          <LazyImage
            src={Logo}
            width={136}
            height={24}
            onClick={handleRedirectToEconomyBookings}
          />
          <div className={styles['button-wrapper']}>
            <OriginButton
              typographyName="body1WBold"
              label={SIGN_IN_LABEL}
              variant="secondary"
              size="large"
              onClick={handleRedirectToMarketplace}
              dataTestId="sign-in-redirect-button"
            />
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

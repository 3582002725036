import { Wrapper } from '@frontend/design_system'
import { Footer } from 'ui/Footer'
import { JoinBlock } from 'modules/JoinBlock'
import { HowItWorks } from 'modules/HowItWorks'
import { Header } from 'ui/Header'
import { BecomePartnerForm } from 'modules/BecomePartnerForm'
import { useRef } from 'react'
import { GeneralNotification } from 'ui/GeneralNotification/GeneralNotification'
import styles from './styles.module.scss'

export const App = () => {
  const formRef = useRef<HTMLDivElement>(null)

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  return (
    <>
      <Header />
      <Wrapper className={styles.layout}>
        <div className={styles.notification}>
          <GeneralNotification />
        </div>
        <JoinBlock onClick={scrollToForm} />
        <HowItWorks />
        <BecomePartnerForm ref={formRef} />
      </Wrapper>
      <Footer />
    </>
  )
}

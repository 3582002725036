import { PATHS } from './constants'
import {
  ApplicationStatus,
  EmptyResponse,
  ErrorResponse,
  LocationType,
  SendApplicationRequest,
  SendApplicationResponse,
} from './types'
import { request } from './utils'

class Api {
  async validateEmail(email: string) {
    return request<
      {
        status: ApplicationStatus
      },
      ErrorResponse
    >(`${PATHS.CHECK_EMAIL}?email=${email}`)
  }

  async getLocationTypes() {
    return request<LocationType[], ErrorResponse>(PATHS.STATION_TYPES)
  }

  async sendApplication(data: SendApplicationRequest) {
    return request<SendApplicationResponse, ErrorResponse>(
      PATHS.SEND_APPLICATION,
      {
        method: 'POST',
      },
      data
    )
  }

  async resendConfirmationLink(email: string) {
    return request<EmptyResponse, ErrorResponse>(
      PATHS.RESEND_LINK,
      {
        method: 'POST',
      },
      { email }
    )
  }
}

export const api = new Api()

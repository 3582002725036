export const STEPS = {
  contactInfo: 1,
  locationAndFleet: 2,
  otherInfo: 3,
}

export const STEP_LABELS = {
  [STEPS.contactInfo]: 'CONTACT INFORMATION',
  [STEPS.locationAndFleet]: 'LOCATION AND FLEET',
  [STEPS.otherInfo]: 'OTHER INFORMATION',
}
